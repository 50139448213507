<template>
  <div class="p-4 pb-0">
    <md-content class="mb-4 p-4 md-sl__q_color br-4">
      <span v-if="question.video_resource_path.match('youtube')">
        <iframe 
          id="player" 
          type="text/html" 
          style="width: 80%;height: 45vh !important;"
          :src="quiz.quiz_details.video_resource_path"
          frameborder="0">
        </iframe>
      </span>  
      <span v-if="!question.video_resource_path.match('youtube')">
        <video controls style="width: 60%;">
          <source :src="quiz.quiz_details.video_resource_path" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
      </span>
    </md-content>
    <md-content class="md-content font-bold mb-4" v-html="quiz.quiz_details.title">
    </md-content>
    <md-content class="mb-2 md-sl__font_600 br-4 mb-4" v-html="quiz.quiz_details.transcript"> 
    </md-content>
  </div>
</template>

<script>
import { getEmbeddedUrl } from "@/utils/videoHelper";
export default {
    components: {
       
    },
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
     
      }
    },
    methods: {
      videoUrl(url) {
        return getEmbeddedUrl(url);
      },
    }
}
</script>
<style scoped>
  video {
    height: 400px;
    object-fit: cover;
  }
</style>