<template>
     <div class="p-4 pt-0">
        <div class="mb-3">
          <h3>Your Answer</h3>
        </div>
        <div class="p-4 pl-6 md-question__radio md-sl__font_600 br-4" style="border: 1px solid #c0c0c0a8">
            <li v-for="(item, index) in quiz.answer_details" :key="index" class="flex align-center">
                <md-checkbox disabled :disable="disable"  :value="item.is_correct_answer === true ? 1 : 0" v-model="disable" :checked="item.is_correct_answer"> </md-checkbox>
                <span :class="item.is_wrong === 0 ? 'text-success' : item.is_wrong === 1 ? 'text-danger' : ''">{{item.value}}</span>
            </li>
        </div>
    </div>
</template>
<script>
export default {
    name: 'multiple',
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
          disable: 1, 
      }
    },
}
</script>