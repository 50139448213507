<template>
    <div class="quiz-set-evaluation">
        <Header :data="{getEvaluation}"/>
        <div v-for="(item,i) in getEvaluation.quizzes" :key="i">
            <div>
                <div class="flex align-center mt-4 ml-3">
                    <h2 class="mt-3">Question</h2>
                    <span class="text-white back-blue br-6 pl-1 pr-1 ml-2 mt-4">{{ item.quiz_details.quiz_id }}</span>
                </div>
                <h4 class="mt-4 ml-4">{{ item.quiz_details.index }}</h4>
            </div>
            <components ref="perent" :is="item.quiz_details.quiz_type_slug" :quiz="item"></components>
            <components ref="child" :is="convertors(item.quiz_details.answer_type)" :quiz="item"></components>
        </div>
        <Footer :layout="layout"/>
    </div>
</template>

<script>
import Header from "./Headers.vue";
import Footer from "./Footers.vue";
import Questions from "@/views/student/evaluation/quiz-set/item";
import { mapGetters, mapActions} from "vuex";
export default {
  components: {
    Header,
    Footer,
    ...Questions
  },
  data() {
    return {
        items:[
            {
              Question_type: 'AudioAndText',
              Answer_type: 'TextAnswer'
            },
            {
              Question_type: 'AudioAndText',
              Answer_type: 'MCQChooseMultipleAnswer'
            },
            {
              Question_type: 'AudioAndText',
              Answer_type: 'MCQChooseSingleAnswer'
            },
            {
              Question_type: 'ImageAndText',
              Answer_type: 'TextAnswer'
            },
            {
              Question_type: 'ImageAndText',
              Answer_type: 'MCQChooseMultipleAnswer'
            },
            {
              Question_type: 'ImageAndText',
              Answer_type: 'MCQChooseSingleAnswer'
            },
            {
              Question_type: 'VideoAndText',
              Answer_type: 'TextAnswer'
            },
            {
              Question_type: 'VideoAndText',
              Answer_type: 'MCQChooseMultipleAnswer'
            },
            {
              Question_type: 'VideoAndText',
              Answer_type: 'MCQChooseSingleAnswer'
            },
            {
              Question_type: 'TextAndText',
              Answer_type: 'TextAnswer'
            },
            {
              Question_type: 'TextAndText',
              Answer_type: 'MCQChooseMultipleAnswer'
            },
            {
              Question_type: 'TextAndText',
              Answer_type: 'MCQChooseSingleAnswer'
            },
        ],
        layout: {
            button: 'View Short Result',
            name: 'short-result.evaluation'
        }
    }
  },
  computed: {
        ...mapGetters({
            getEvaluation: "quiz/getEvaluation",
      
        }),
        quizId() {
            return this.$route.params.id
        }
    },
    methods: {
      ...mapActions({
        actEvaluationData: "quiz/actEvaluationData",
      }), 
      convertors(title){
        if(title === 'MCQ-CHOOSE-MULTIPLE-ANSWER'){
          return 'MCQChooseMultipleAnswer'; 
        }
        if(title === 'MCQ-CHOOSE-SINGLE-ANSWER'){
          return 'MCQChooseSingleAnswer'; 
        } 
        if( title === 'TEXT'){
          return 'TextAnswer'
        }
      }, 
    },
    created() {
        this.actEvaluationData(this.quizId);
    },
};
</script>
<style scoped>

</style>