<template>
  <div class="p-4 pt-1">
    <md-content  class="md-content mb-4" v-html="quiz.quiz_details.title">
    </md-content>
    <md-content class="mb-2 md-sl__font_600 br-4" v-html="quiz.quiz_details.transcript"> 
    </md-content>
  </div>
</template>

<script>

export default {
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
         
      }
    },
}
</script>