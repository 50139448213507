<template>
  <div class="p-4 pb-0">
    <Audio :src="quiz.quiz_details.audio_resource_path"/>
    <md-content  class="md-content font-bold mb-6" v-html="quiz.quiz_details.title">
    </md-content>
    <md-content class="mb-2 md-sl__font_600 br-4 mb-4" v-html="quiz.quiz_details.transcript"> 
    </md-content>
  </div>
</template>

<script>
import Audio from '@/components/molecule/question/audio.vue'
export default {
    components: {
       Audio
    },
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
       
      }
    },
}
</script>