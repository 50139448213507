<template>
  <div class="p-4 pb-0">
    <md-content class="mb-4 p-4 md-sl__q_color br-4">
      <img :src="quiz.quiz_details.image_resource_path"/>
    </md-content>
    <md-content  class="md-content font-bold mb-4" v-html="quiz.quiz_details.title">
    </md-content>
    <md-content class="mb-2 md-sl__font_600 br-4 mb-4" v-html="quiz.quiz_details.transcript"> 
    </md-content>
  </div>
</template>

<script>

export default {
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
        
      }
    },
}
</script>
<style scoped>
img {
  height: 400px;
  object-fit: cover;
  width: 100%;
}
</style>