<template>
  <div class="p-4 pt-0">
    <div class="mb-3">
      <h3>Your Answer</h3>
    </div>
    <md-content v-html="this.yourAnswer" class="md-content mb-4 p-3 br-4 md-sl__font_600" style="border: 1px solid #c0c0c0a8">
    </md-content>
    <div class="mb-2 br-4 md-sl__font_600 br-4 rounded-lg p-3" style="background-color: #f0faef">
      <h3 class="mb-2">Correct Answer</h3>
      <md-content style="background-color: #f0faef" v-html="quiz.quiz_details.answer">
      </md-content>
    </div>
  </div>
</template>

<script>
export default {
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
        yourAnswer: '',
      }
    },
    mounted(){
      let text = this.quiz.answer_details;
      let wrong = this.quiz.evaluation_details.wrong;
      let correct = this.quiz.evaluation_details.correct;
      let wrongAnswer = wrong.join("|");
      let correctAnswer = correct.join("|");
      const wrongRe = new RegExp(`(${wrongAnswer})`, 'gi');
      const correctRe = new RegExp(`(${correctAnswer})`, 'gi');
      if(wrong.length > 0 && correct.length === 0){
        this.yourAnswer = text.replace(wrongRe,'<span class="text-danger">$1</span>');
      } else if(correct.length > 0 && wrong.length === 0) {
       this.yourAnswer = text.replace(correctRe,'<span class="text-success">$1</span>');
      }
      else if(correct.length > 0 && wrong.length > 0) {
       this.yourAnswer = text.replace(correctRe,'<span class="text-success">$1</span>').replace(wrongRe,'<span class="text-danger">$1</span>');
      } else {
        this.yourAnswer = text;
      }
    }
}
</script>