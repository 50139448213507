<template>
     <div class="p-4 pt-0"><div class="mb-3">
          <h3>Your Answer</h3>
        </div>
        <div class="p-3 md-question__radio md-sl__font_600 br-4" style="border: 1px solid #c0c0c0a8">
          <ul>
            <li v-for="(item, index) in quiz.answer_details" :key="index" class="m-0 flex align-center">
              <md-radio  disabled :disable="disable"  :value="item.is_correct_answer === true ? 1 : 0" v-model="disable" :checked="item.is_correct_answer" class="md-primary m-3"> </md-radio>
              <span :class="item.is_wrong === 0 ? 'text-success' : item.is_wrong === 1 ? 'text-danger' : ''">{{item.value}}</span>
            </li>
          </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'single',
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
        disable: 1,
         list:[
            {
              id: 1,
              title: 'hello',
              is_correct_answer: 0,
            },
            {
              id: 2,
              title: 'hello2',
              is_correct_answer: 1,
              correct: 0
            },
            {
              id: 3,
              title: 'hello3',
              is_correct_answer: 0,
              correct: 1
            },
            {
              id: 4,
              title: 'hello4',
              is_correct_answer: 0,
            }
          ],
        
      }
    },
    mounted(){
       
    },
    watch: {
        radio(value) {
            console.log(value)
        }
    }
   
}
</script>
<style scoped>

</style>